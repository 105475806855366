import React from "react";
import rCover from "../../assets/gallery/r1.jpeg";
import r1 from "../../assets/gallery/r1.jpeg";
import r2 from "../../assets/gallery/r2.jpeg";
import r3 from "../../assets/gallery/r3.jpeg";
import r4 from "../../assets/gallery/r4.jpeg";

// import papD6 from "../../../assets/gallery/papD1.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sf88 = () => {
  return (
    <div className="text-white bg-black">
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-black">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-5xl md:text-center leading-8 font-Lato font-normal tracking-wider md:p-6  ">
             ASTRA ACR
            </h3>
            <h3 className="font-Lato font-normal md:text-center pb-3 text-md md:text-lg tracking-wide">
             Glass railing system
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={rCover}
                alt="Alumil sf85 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-Lato font-normal md:text-base tracking-narrow pb-5">
              Vertical glass support system for glass railings of modern aesthetics and top robustness. Continuous base profiles without mullions, to leave the visual field free and offer a sense of luxurious freedom.
              </p>
              <p className=" text-left .leading-8 font-Lato font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-Lato font-normal   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                  Extreme sturdiness due to the continuous profiles
                  </li>
                  <li>
                  Easy and fast to install
                  </li>
                  <li>
                  Constructions of great lengths without any glass alignment issues

                  </li>
                  <li>
                  Extensive variety in terms of placement, glass thickness, handrails and surface treatment

                  </li>
                  <li>
                  Top quality in terms of design and production
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={rCover}
              alt="Alumil sf85 Cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-black">

      <div className=" md:block w-full md:w-4/4 pt-2 pb-12 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              
              <div class="w-full ">
                <img src={r1} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={r2} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={r3} alt="Alumil sf85 example" />
              </div>
              <div class="w-full ">
                <img src={r4} alt="Alumil sf85 example" />
              </div>
              {/* <div class="w-full ">
                <img src={papD6} alt="Alumil sf85 example" />
              </div> */}
            </div>
          </div>

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            

          <h3 className="md:pl-32 font-Lato font-semibold text-left tracking-widest text-white text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              GENERIC INFO
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-Lato  md:text-base text-left pt-6 uppercase ">

              <span className="pr-10 font-semibold ">
                 PLACEMENT - INSTALLATION
                  <span className="pr-10 block font-normal pb-4">on-floor, side-mounted, embedded, curved</span>
                </span>
                <span className="pr-10 font-semibold ">
                USE
                  <span className="pr-10 block font-normal pb-4">Outdoor, Indoor, Seaside</span>
                </span>
                <span className="pr-10 font-semibold ">
                APPLICATIONS
                  <span className="pr-10 block font-normal pb-4">
                  modern residences, hotels, malls, commercial and office buildings
                  </span>
                </span>
              
                
          </div>
          </div>

            <h3 className="md:pl-32 font-Lato font-semibold text-left tracking-widest text-white text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-Lato  md:text-base text-left pt-6 uppercase ">
               
                <span className="pr-10 font-semibold ">
                GLAZING TYPE
                  <span className="pr-10 block font-normal pb-4">laminate tempered</span>
                </span>
                <span className="pr-10 font-semibold ">
                GLASS THICKNESS
                  <span className="pr-10 block font-normal pb-4">12+12, 10+10, 8+8, 6+6, 5+5 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                MAXIMUM HEIGHT
                  <span className="pr-10 block font-normal pb-4">
                 1.6 m
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                MAX STRUCTURE LENGTH
                  <span className="pr-10 block font-normal pb-4">
                  unlimited
                  </span>
                </span>
                

                <span className="pr-10 font-semibold ">
                HANDRAILS
                  <span className="pr-10 block font-normal pb-4">
                 various shapes (flat, oval, round, square)
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                END COVERS
                  <span className="pr-10 block font-normal pb-4">inox and coated aluminum</span>
                </span>
                <span className="pr-10 font-semibold ">
                LIGHTING
                  <span className="pr-10 block font-normal pb-4">with 8+8mm glass thickness</span>
                </span>
                <span className="pr-10 font-semibold ">
                PRE-DRILLED PROFILES
                  <span className="pr-10 block font-normal pb-4">
                  yes, and for counter shank screws
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                LINEAR HORIZONTAL LOAD
                  <span className="pr-10 block font-normal pb-4">
                  up to 4.0KN/m
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                WIND LOAD RESISTANCE
                  <span className="pr-10 block font-normal pb-4">
                 up to 5.0KN/m2
                  </span>
                </span>
          
              
              </div> 
              </div>

            {/* <h3 className="md:pl-32 font-Lato font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-Lato text-left pt-6 ">
              <span className="pr-10 pb-4 ">
                <img
                  src={sf851}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={sf852}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents plus door
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sf853}
                  alt="Alumil sf85 Typology "
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf854}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={sf855}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with single door
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={sf856}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with odd number of vents on both sides
              </span>

              <span className="pr-10 pb-4 ">
                <img
                  src={sf857}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24 mx-auto "
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sf858}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={sf859}
                  alt="Alumil sf85 Typology "
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents plus door
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf8510}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf8510}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Even & odd number of vents on both sides (meeting stile)
              </span> 
            </div>  */}
{/* 
            <h3 className="md:pl-32 md:pt-12 font-Lato font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>  */}
          </div>
         
        </div>
      </div>
      {/* <div className="  flex flex-col justify-center  pb-2">
        <div className="  md:max-w-9xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  max-w-8xl;  mx-auto bg-black ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec1}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec2}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec3}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec4}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec5}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:w-2/2 grid place-items-center  ">
            <img
              src={sf85sec6}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div> */}
{/* 
      <h3 className="md:pl-32 md:pt-12 font-Lato font-semibold text-left tracking-widest text-white text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 md:pb-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/alumil-pergola.pdf?sfvrsn=649368bf_38"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            Alumil Pergolas
          </a>
        </div>
      </div> */}

      {/* <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/supreme-sf85.pdf?sfvrsn=a54b85df_21"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SF85 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/sf85_en.pdf?sfvrsn=4705adb6_35"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            SF85 Brochure
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Sf88;
