import React, { useEffect } from "react";
import AOS from 'aos';
import { Link } from "react-router-dom"; // Import the Link component
import ProductCover from ".././assets/gallery/pg1.jpg";
import Product from ".././assets/gallery/pg4.webp";
import Pap from ".././assets/gallery/papD1.jpeg";
import Partition from ".././assets/gallery/p1.jpg";
import Slider from ".././assets/gallery/sliderCover.jpg";
import Door from ".././assets/gallery/d2.jpg";
import Railing from ".././assets/gallery/r1.jpeg";
import bifold from ".././assets/doors/door3.webp";
import lift from ".././assets/doors/door4.webp";
import slide from ".././assets/doors/door5.webp";
import entrance from ".././assets/doors/door6.webp";
import pivot from ".././assets/doors/Pivot.webp";
import swing from ".././assets/doors/door8.webp";
import "./Home.css";

const Products = () =>  {useEffect(() => {
  AOS.init({ duration: 1200 });
}, []);
  return (
    <div className="">

<div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full p-12 pb-6 md:p-36">


<div data-aos="fade-up">
<img src={ProductCover} alt="Image 2" className="object-cover md:pt-4 w-full h-full" />
</div>

<div className=" text-black w-full md:w-3/3 md:pt-12 pt-2 p-6 flex flex-col space-y-2 md:p-10 items-start ">
    <div data-aos="fade-up">
     <h3 className="font-montserrat font-normal text-[#607261] text-left md:pb-12 text-lg md:text-4xl tracking-standard">
        Our Products
      </h3>
      <p className=" text-left md:text-lg font-montserrat font-normal pb-7">
      Welcome to Astra Concepts, where luxury and functionality merge in outdoor living. Specializing in high-end aluminum pergolas and shading systems, we aim to transform your backyard into a stylish relaxation haven. Our products, ranging from modern to classic designs, embody durability, weather resistance, and aesthetic appeal, reflecting our commitment to quality and detail.

At Astra Concepts, we view your outdoor space as a potential masterpiece, with our pergolas as the central art. Driven by precision and innovation, we cater to diverse tastes, ensuring that each design aligns with our clients' dreams, redefining luxury in every backyard.
      </p>
      
      </div>
    </div>

    {/* NEED TO MAKE THE TEXT CLICKABLE AND FIX IMAGE SIZES NOT SURE WHY DISCREPENCY HERE */}

    
    
    <div className=" w-full md:w-2/2 md:pt-12 grid place-items-center transform transition duration-500 hover:scale-105">
              <Link to="pergolas">
              <img
                src={Product}
                alt="Aluminum Pergola"
                className="object-fit w-full"
              />
              </Link>
              <h3 className="font-normal text-[#607261] text-center md:pb-12 text-lg md:text-4xl tracking-standard md:text-4xl md:pt-12">
                Aluminum Pergola Systems
              </h3>
          </div>

        <div className="w-full md:w-2/2 md:pt-12 grid place-items-center transform transition duration-500 hover:scale-105 ">
            <Link to="partitions">
            <img
                src={Partition}
                alt="Alumiunm Interior Doors"
                className="object-fit w-full"
              />
               </Link>
              <h3 className="font-normal text-[#607261] text-center md:pb-12 text-lg md:text-4xl tracking-standard md:text-4xl md:pt-12">
                Aluminum Partition Systems
              </h3>
           
          </div>

          <div className=" w-full md:w-2/2 md:pt-12 grid place-items-center transform transition duration-500 hover:scale-105">
              <Link to="doors">
              <img
                src={Door}
                alt="Aluminum Door Systems"
                className="object-fit w-full"
              />
               </Link>
              <h3 className="font-normal text-[#607261] text-center md:pb-12 text-lg md:text-4xl tracking-standard md:text-4xl md:pt-12">
                Aluminum Door Systems
              </h3>
           
          </div>

          <div className=" w-full md:w-2/2 md:pt-12 grid place-items-center transform transition duration-500 hover:scale-105">
              <Link to="sliders">
              <img
                src={Slider}
                alt="Aluminum Sliding Systems"
                className="object-fit w-full"
              />
               </Link>
              <h3 className="font-normal text-[#607261] text-center md:pb-12 text-lg md:text-4xl tracking-standard md:text-4xl md:pt-12">
                Aluminum Sliding Systems
              </h3>
           
          </div>
   
</div>



    

  
    </div>
  );
};

export default Products;
