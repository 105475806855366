import React from "react";
import sliderCover from "../../assets/gallery/sliderBg.jpg";
import sliderD1 from "../../assets/gallery/slider1.jpg";
import sliderD2 from "../../assets/gallery/slider1c.jpg";
import sliderD3 from "../../assets/gallery/slider2.jpg";
import sliderD4 from "../../assets/gallery/slider2c.jpg";
import sliderD5 from "../../assets/gallery/slider3.jpg";
import sliderD6 from "../../assets/gallery/slider3c.jpg";
import sliderD7 from "../../assets/gallery/slider4.jpg";
import sliderD8 from "../../assets/gallery/slider5.jpg";
// import V2000 from "../../assets/gallery/V2000.jpeg";
// import SA1000 from "../../assets/gallery/SA1000.jpeg";

// import papD6 from "../../../assets/gallery/papD1.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sf89 = () => {
  return (
    <div className=" ">
    <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  ">
      <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
        <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
          <h3 className="text-4xl md:text-5xl md:text-center leading-8 font-Lato font-normal tracking-wider md:p-6  ">
           ASTRA ACS
          </h3>
          <h3 className="font-Lato font-normal text-[#607261] md:text-center pb-3 text-md md:text-lg tracking-wide">
           SIDING DOORS
          </h3>

          <div className=" md:hidden max-w-xl pb-6 ">
            <img
              src={sliderCover}
              alt="Alumil sf85 Cover"
              className="object-cover w-full h-full"
            />
          </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-Lato font-normal md:text-base tracking-narrow pb-5">
              Welcome to Astra Concepts' exclusive collection of sliding doors, designed to meet all your needs with elegance and functionality. Whether you're looking to create stylish partitions or contemporary interior doors, we have something to suit every style and application. Our sliding doors are not just mere passageways; they are a statement of your taste and a reflection of your lifestyle.


              </p>
              <p className=" text-left .leading-8 font-Lato font-normal  md:text-base tracking-narrow md:pb-2 pb-5">
              Each door in our collection is crafted with precision and is highly customizable to fit your specific requirements. Experience the blend of aesthetics and practicality with our interior doors, and transform your space into a realm of sophistication and comfort.
              </p>
              <p className=" text-left .leading-8 font-Lato font-normal   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                  Ideal for modern spaces or when you're looking to save room, our sliding doors offer sleek functionality without compromising on style. Customize them to fit any space and match any theme.
                  </li>
                
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={sliderCover}
              alt="Alumil sf85 Cover"
              className="object-cover w-ful h-full"
            />
          </div>
        </div>
      </div>

      <div className=" flex flex-col justify-center  pb-12  ">

      <div className=" md:block w-full md:w-4/4 pt-2 pb-12 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="object-cover  md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="">
                <img className="object-cover w-full h-full" src={sliderD1} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={sliderD2} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full"src={sliderD3} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={sliderD4} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={sliderD5} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={sliderD6} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={sliderD7} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={sliderD8} alt="Alumil sf85 example" />
              </div>
              {/* <div class="">
                <img className="object-cover w-full h-full" src={p9} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p10} alt="Alumil sf85 example" />
              </div> */}

              {/* <div class="w-full ">
                <img src={papD6} alt="Alumil sf85 example" />
              </div> */}
            </div>
          </div>

      </div>
     
    </div>
  );
};

export default Sf89;
