import React, { useEffect } from "react";
import AOS from 'aos';
import abCover from "./../assets/about/abCover.jpg";
import brainstorm from "./../assets/about/brainstorm.webp";
import prototyping from "./../assets/about/prototyping.webp";
import completion from "./../assets/about/completion.webp";
import commitment from "./../assets/about/commitment.webp";
import { Link } from "react-router-dom";


const About = () => {useEffect(() => {
  AOS.init({ duration: 1200 });
}, []);
  return (

    
    <div data-aos="fade-up">

        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full p-12 pb-6 md:p-36 h-screen">

        <img src={abCover} alt="Image 2" className="object-cover md:pt-4 w-full h-full" />

        <div className=" text-black w-full md:w-3/3 md:pt-12 pt-2 p-6 flex flex-col space-y-2 md:p-10 items-start ">
            <div data-aos="fade-up">
             <h3 className="font-Lato font-normal text-[#607261] text-left md:pb-12 text-lg md:text-4xl tracking-standard">
                About Us
              </h3>
              <p className=" text-left md:text-lg font-Lato font-normal pb-7">
              Welcome to Astra Concepts, where luxury and functionality merge in outdoor living. Specializing in high-end aluminum pergolas and shading systems, we aim to transform your backyard into a stylish relaxation haven. Our products, ranging from modern to classic designs, embody durability, weather resistance, and aesthetic appeal, reflecting our commitment to quality and detail.

At Astra Concepts, we view your outdoor space as a potential masterpiece, with our pergolas as the central art. Driven by precision and innovation, we cater to diverse tastes, ensuring that each design aligns with our clients' dreams, redefining luxury in every backyard.
              </p>
              
              </div>
            </div>


</div>
    </div>


  );
};

export default About;
