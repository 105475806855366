import React from "react";
import papCover from "../../assets/gallery/dCover.jpg";
import papD1 from "../../assets/gallery/a1.jpg";
import papD2 from "../../assets/gallery/a1c.jpg";
import papD3 from "../../assets/gallery/a2.jpg";
import papD4 from "../../assets/gallery/a2c.jpg";
import papD5 from "../../assets/gallery/a21.jpg";
import papD6 from "../../assets/gallery/a21c.jpg";
import papD7 from "../../assets/gallery/a22.jpg";
import papD8 from "../../assets/gallery/a22c.jpg";
// import V2000 from "../../assets/gallery/V2000.jpeg";
// import SA1000 from "../../assets/gallery/SA1000.jpeg";

// import papD6 from "../../../assets/gallery/papD1.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sf86 = () => {
  return (
    <div className=" ">
    <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  ">
      <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
        <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
          <h3 className="text-4xl md:text-5xl md:text-center leading-8 font-Lato font-normal tracking-wider md:p-6  ">
           ASTRA ACP
          </h3>
          <h3 className="font-Lato font-normal text-[#607261] md:text-center pb-3 text-md md:text-lg tracking-wide">
           INTERIOR DOORS
          </h3>

          <div className=" md:hidden max-w-xl pb-6 ">
            <img
              src={papCover}
              alt="Alumil sf85 Cover"
              className="object-cover w-full h-full"
            />
          </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-Lato font-normal md:text-base tracking-narrow pb-5">
              Welcome to our exclusive collection of Aluminum interior doors, designed to meet all your needs with elegance and functionality. Whether you're looking for classic hinge doors or contemporary sliding doors, we have something to suit every style and application. Our doors are not just mere passageways; they are a statement of your taste and a reflection of your lifestyle.


              </p>
              <p className=" text-left .leading-8 font-Lato font-normal  md:text-base tracking-narrow md:pb-2 pb-5">
              Each door in our collection is crafted with precision and is highly customizable to fit your specific requirements. Experience the blend of aesthetics and practicality with our interior doors, and transform your space into a realm of sophistication and comfort.
              </p>
              <p className=" text-left .leading-8 font-Lato font-normal   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                  Timeless in design, our hinge doors are perfect for those who appreciate classic styles. Available in various materials and finishes, they can be customized to match your unique décor.
                  </li>
                
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={papCover}
              alt="Alumil sf85 Cover"
              className="object-cover w-ful h-full"
            />
          </div>
        </div>
      </div>

      <div className=" flex flex-col justify-center  pb-12  ">

      <div className=" md:block w-full md:w-4/4 pt-2 pb-12 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="object-cover  md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="">
                <img className="object-cover w-full h-full" src={papD1} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={papD2} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full"src={papD3} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={papD4} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={papD5} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={papD6} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={papD7} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={papD8} alt="Alumil sf85 example" />
              </div>
              {/* <div class="">
                <img className="object-cover w-full h-full" src={p9} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p10} alt="Alumil sf85 example" />
              </div> */}

              {/* <div class="w-full ">
                <img src={papD6} alt="Alumil sf85 example" />
              </div> */}
            </div>
          </div>

      </div>
     
    </div>
  );
};

export default Sf86;
