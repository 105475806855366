import React, { useEffect, useState } from "react";
import "./Home.css";

const IntroAnimation = () => {
  const [animationFinished, setAnimationFinished] = useState(false);

  useEffect(() => {
    const animateLogo = () => {
      const logoSpan = document.querySelectorAll(".logo2");

      logoSpan.forEach((span, idx) => {
        setTimeout(() => {
          span.classList.add("active");
        }, (idx + 1) * 300);
      });

      setTimeout(() => {
        logoSpan.forEach((span, idx) => {
          setTimeout(() => {
            span.classList.remove("active");
            span.classList.add("fade");
          }, (idx + 1) * 50);
        });
      }, 2000);

      setTimeout(() => {
        setAnimationFinished(true);
      }, 2800);
    };

    const animationPlayedSessionStorage = sessionStorage.getItem("animationPlayed");

    if (!animationPlayedSessionStorage) {
      animateLogo();
      sessionStorage.setItem("animationPlayed", "true"); // Store in session storage
    } else {
      setAnimationFinished(true); // If animation already played during the session
    }
  }, []);

  return (
    <div className={`intro ${animationFinished ? "hidden" : ""}`}>
      <div className="top-half">
        <h1 className="logo-header font-Lato text-4xl md:text-10xl font-thin leading-8 tracking-widest uppercase">
          <span className="logo2">A</span>
          <span className="logo2">S</span>
          <span className="logo2">T</span>
          <span className="logo2">R</span>
          <span className="logo2">A</span>
        </h1>
      </div>
      <div className="bottom-half">
        <h2 className="logo-header font-Lato md:pt-32 text-3xl md:text-4xl font-thin leading-8 tracking-widest uppercase">
          <span className="logo2">CONCEPTS</span>
        </h2>
      </div>
    </div>
  );
};

export default IntroAnimation;
