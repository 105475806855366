import React from "react";
import pCover from "../../assets/gallery/p1.jpg";
import p1 from "../../assets/gallery/p2.jpg";
import p2 from "../../assets/gallery/p3.webp";
import p3 from "../../assets/gallery/p4.jpg";
import p4 from "../../assets/gallery/p5.jpg";
import p6 from "../../assets/gallery/p6.png";
import p7 from "../../assets/gallery/p7.jpg";
import p8 from "../../assets/gallery/p8.jpg";
import p9 from "../../assets/gallery/p9.jpg";
import p10 from "../../assets/gallery/p10.jpg";

// import papD6 from "../../../assets/gallery/papD1.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sf87 = () => {
  return (
    <div className=" ">
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  ">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-5xl md:text-center leading-8 font-Lato font-normal tracking-wider md:p-6  ">
             ASTRA ACO
            </h3>
            <h3 className="font-Lato font-normal text-[#607261] md:text-center pb-3 text-md md:text-lg tracking-wide">
             Office Partition System
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={pCover}
                alt="Alumil sf85 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-Lato font-normal md:text-base tracking-narrow pb-5">
              P7000 is a structural partitioning system with minimum aluminium face width and large glass panels hanging on the concealed aluminum framework, at both sides. The series offers spaces centration, rich lighting and undoubtedly modern aesthetics.
              </p>
              <p className=" text-left .leading-8 font-Lato font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-Lato font-normal   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                  Modern aesthetics with fully glazed wall surfaces (structural)
                  </li>
                  <li>
                  Minimal design thanks to the very thin lines
                  </li>
                  <li>
                  High sound insulation for comfortable working conditions
                  </li>
                  <li>
                  Possibility of complex typologies, e.g., cross or “T” shape structures
                  </li>
                  <li>
                  Concealed and robust aluminium framework to allow large dimensions
Certificates
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={pCover}
              alt="Alumil sf85 Cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  ">

      <div className=" md:block w-full md:w-4/4 pt-2 pb-12 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="object-cover  md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="">
                <img className="object-cover w-full h-full" src={p1} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p2} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full"src={p3} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p6} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p7} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p8} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p9} alt="Alumil sf85 example" />
              </div>
              <div class="">
                <img className="object-cover w-full h-full" src={p10} alt="Alumil sf85 example" />
              </div>

              {/* <div class="w-full ">
                <img src={papD6} alt="Alumil sf85 example" />
              </div> */}
            </div>
          </div>

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            

          <h3 className="md:pl-32 font-Lato font-semibold text-left tracking-widest  text-base underline decoration-4 decoration-[#607261] leading-6 underline-offset-8">
              GENERIC INFO
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-Lato  md:text-base text-left pt-6 uppercase ">

              <span className="pr-10 font-semibold ">
                 USE
                  <span className="pr-10 block font-normal pb-4">Partitioning system</span>
                </span>
                <span className="pr-10 font-semibold ">
                APPLICATIONS
                  <span className="pr-10 block font-normal pb-4">Office buildings, meeting rooms, hotels, airports etc.</span>
                </span>
                <span className="pr-10 font-semibold ">
                CONSTRUCTIONS
                  <span className="pr-10 block font-normal pb-4">
                  Grid, corner, “T”, cross structure
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                PANEL
                  <span className="pr-10 block font-normal pb-4">
                  Structural, with adhesive stripe or silicone
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                 DOOR TYPE
                  <span className="pr-10 block font-normal pb-4">One-sided structural framed glass panel</span>
                </span>
                <span className="pr-10 font-semibold ">
                 SHADING
                  <span className="pr-10 block font-normal pb-4">Incorporated adjustable blind shutter</span>
                </span>
               
                
          </div>
          </div>

         
          </div>
         
        </div>
      </div>
      
    </div>
  );
};

export default Sf87;
