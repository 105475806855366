import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useForm, ValidationError } from '@formspree/react';



function ContactForm() {
  const [state, handleSubmit] = useForm("mzbneaob");
  if (state.succeeded) {
      return <p>Thanks for reaching out to us!</p>;
  }
  return (
    
    <div className="font-Lato" >
     <h1 className="font-lato font-normal text-[#607261] text-center md:pt-32 md:pb-12 text-lg md:text-4xl tracking-standard">
       CONTACT US
      </h1>
      <div className=" flex flex-col justify-center md:pt-12 pb-32 ">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-6 md:p-14 max-w-sm md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/4 grid place-items-left">
        
            <p className=" text-left .leading-8  font-Lato font-medium tracking-narrow pb-5">
            Your thoughts and inquiries matter to us! We're here to assist you. Please don't hesitate to reach out by filling in the email form below. Whether you have questions, feedback, or just want to say hello, we're eager to hear from you. Thank You
            </p>
            <p className=" text-left .leading-8  font-Lato font-black tracking-narrow pb-5">
              <FontAwesomeIcon
                className="pr-2"
                icon={faPhone}
                style={{ color: "#607261" }}
              />{" "}
              604 213 2425
            </p>
            <p className=" text-left .leading-8  font-Lato font-black tracking-narrow pb-5">
              <FontAwesomeIcon
                className="pr-2"
                icon={faEnvelope}
                style={{ color: "#607261" }}
              />{" "}
              info@astraconcepts.ca
            </p>
            <p className=" flex  text-left .leading-8  font-Lato font-black tracking-narrow pb-5">
              <FontAwesomeIcon
                className="pl-1 pr-2 py-1"
                icon={faLocationDot}
                style={{ color: "#607261" }}
              />{" "}
              105 - 15292 Croydon Drive, Surrey, British Columbia V3Z 0Z5,
              Canada
            </p>
          </div>
          <div className=" w-full md:w-3/4 text-left flex flex-col space-y-2 md:">
          
            <form onSubmit={handleSubmit}>
              <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    for="first_name"
                    class="block mb-2 font-Lato font-medium "
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="name"
                    class="bg-gray-50 text-black border border-[#607261] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="First name"
                  />
                </div>
                <div>
                  <label
                    for="last_name"
                    class="block mb-2 font-Lato font-medium "
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="name"
                    class="bg-gray-50 text-black border border-[#607261] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="Last Name"
                  />
                </div>
                <div>
                  <label
                    for="email"
                    class="block mb-2 font-Lato font-medium "
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="_replyto"
                    class="bg-gray-50 text-black border border-[#607261] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="name@mail.com"
                  />
                </div>
                <div>
                  <label
                    for="phone"
                    class="block mb-2 font-Lato font-medium "
                  >
                    Phone number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    class="bg-gray-50 text-black border border-[#607261] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="123-456-7891"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  />
                </div>
                <div>
                  <label
                    for="message"
                    class="block mb-2 font-Lato font-medium "
                  >
                    Message
                  </label>
                  <textarea
                    type="textarea"
                    id="message"
                    name="message"
                    class="bg-gray-50 text-black border border-[#607261] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="Enter your message"
                  />
                </div>
              </div>
              <button
                type="submit" disabled={state.submitting}
                class="bg-[#607261] hover:bg-[#000000] text-xs font-Lato font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500 "
              >
                SEND MESSAGE
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="p-36">

      </div>
    </div>
  );
};

export default ContactForm;

