import React from "react";
import pg1 from "./../assets/gallery/pg1.jpg";
import pg2 from "./../assets/gallery/pg2.jpg";
import pg3 from "./../assets/gallery/pg3.jpg";
import pg4 from "./../assets/gallery/pg4.webp";
import pg5 from "./../assets/gallery/pg5.jpg";
import pg6 from "./../assets/gallery/pg6.jpg";
import pg7 from "./../assets/gallery/pg7.webp";
import pg8 from "./../assets/gallery/pg8.webp";
import pg9 from "./../assets/gallery/pg9.webp";
import pg10 from "./../assets/gallery/pg10.webp";
import pg11 from "./../assets/gallery/pg11.webp";
import pg12 from "./../assets/gallery/pg12.webp";
import pg13 from "./../assets/gallery/pg13.webp";
import pg14 from "./../assets/gallery/pg14.webp";
import pg15 from "./../assets/gallery/pg15.webp";
import d1 from "./../assets/gallery/i1.jpeg";
import d2 from "./../assets/gallery/i2.png";
import d3 from "./../assets/gallery/i3.jpeg";
import d4 from "./../assets/gallery/i4.jpeg";
import p1 from "./../assets/gallery/p1.jpg";
import p2 from "./../assets/gallery/p2.jpg";
import p3 from "./../assets/gallery/p3.webp";
import p4 from "./../assets/gallery/p4.jpg";
import p5 from "./../assets/gallery/p5.jpg";
import p6 from "./../assets/gallery/p6.jpeg";
import r1 from "./../assets/gallery/r1.jpeg";
import r2 from "./../assets/gallery/r2.jpeg";
import r3 from "./../assets/gallery/r3.jpeg";
import r4 from "./../assets/gallery/r4.jpeg";



const Gallery = () => {
  return (
    <div  >


<div data-aos="fade-up">
     <h1 className="font-Lato font-normal text-[#607261] text-center md:pt-32 md:pb-12 text-lg md:text-4xl tracking-standard">
       GALLERY
      </h1>
      
    

      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:2/2 grid place-items-center transform transition duration-500 hover:scale-105">
            
              <img
                src={pg1}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg2}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className="  w-full md:2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg3}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg4}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className="  w-full md:2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg5}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg6}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className="  w-full md:2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg7}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg8}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
        </div>

        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className="  w-full md:2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg9}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg10}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          </div>
       
          <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg11}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg12}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          </div>
          <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg13}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={pg14}
                alt="Pergola"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={d1}
                alt="ACO Door"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={d2}
                alt="ACO Door"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={d3}
                alt="ACO Door"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={d4}
                alt="ACO Door"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={p6}
                alt="Partition"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={p1}
                alt="Partition"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={p2}
                alt="Partition"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={p3}
                alt="Partition"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={p4}
                alt="Partition"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={p5}
                alt="Partition"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={r1}
                alt="Railing"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={r2}
                alt="Railing"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0  md:pb-6 max-w-xl md:max-w-6xl mx-auto ">
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={r3}
                alt="Railing"
                className="object-cover w-full"
              />
           
          </div>
          <div className=" w-full md:w-2/2 grid place-items-center transform transition duration-500 hover:scale-105 ">
            
              <img
                src={r4}
                alt="Railing"
                className="object-cover w-full"
              />
           
          </div>
        </div>
        </div>
        </div>
    </div>

  );
};

export default Gallery;
