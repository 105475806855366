import React from "react";
import sf85Cover from "../../assets/gallery/pg1.jpg";
import sf85a from "../../assets/gallery/pg2.jpg";
import sf85b from "../../assets/gallery/pg3.jpg";
import sf85c from "../../assets/gallery/pg4.webp";
import sf85d from "../../assets/gallery/pg5.jpg";
import sf85e from "../../assets/gallery/pg6.jpg";
import sf85f from "../../assets/gallery/pg7.webp";
import PG120 from "../../assets/gallery/PG120.jpeg";
import PG120Shade from "../../assets/gallery/PG120Shade.jpeg";
import PG160P from "../../assets/gallery/PG160P.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const Sf85 = () => {
  return (
    <div className="">
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  ">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-5xl md:text-center leading-8 font-Lato font-normal tracking-wider md:p-6  ">
              ASTRA SANTORINI 
            </h3>
            <h3 className="font-Lato text-[#607261] font-normal md:text-center pb-3 text-md md:text-lg tracking-wide">
              Bioclimatic Pergola
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={PG120}
                alt="Pergola"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-Lato font-normal md:text-base tracking-narrow pb-5">
              ASTRA Santorini, a system for outstanding bioclimatic pergolas, is characterized by the high durability and the innovations it incorporates, offering ideal shading solutions for outdoor applications. It is probably the only pergola system that can cover in free-standing typologies (without wall mounting) a surface of up to 50 m2 with only four columns. The possible covered surface can be even doubled by adding just two more columns (i.e. 100 m2 with only 6 columns), without affecting the elegant and minimal design of the pergola.

The rotating louvers for adjustable shading and protection from bad weather conditions, as well as the plethora of the available equipment, reflect the system’s bioclimatic philosophy and guarantee excellent living conditions all year round.
              </p>
              <p className=" text-left .leading-8 font-Lato font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-Lato font-normal   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                  Efficient protection against sunlight, rain, snow and other weather conditions
                  </li>
                  <li>
                  Controlled living conditions due to the bioclimatic philosophy and side elements integration, such as sliding glass system, rolling shading ZIP-screens, shutters, LED, etc.
                  </li>
                  <li>
                  Extremely robust constructions, which allows very wide dimensions up to 7.5 x 7.5 m with just four columns
                  </li>
                  <li>
                  State-of-the-art fittings, e.g. the patented concealed and noiseless mechanism and the integrated automation systems
                  </li>
                  <li>
                  High aesthetics and minimal design with emphasis on detail, e.g. no visible fixation points
                  </li>
                  <li>
                  Vast range of solutions and possibility of full customization and expandability to meet every need
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={PG120}
              alt="Alumil sf85 Cover"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12 ">

      <div className=" md:block w-full md:w-4/4 pt-2 pb-12 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={sf85a} alt="Alumil Pergola" />
              </div>
              <div class="w-full ">
                <img src={sf85b} alt="Alumil Pergola" />
              </div>
              <div class="w-full ">
                <img src={sf85c} alt="Alumil Pergola" />
              </div>
              <div class="w-full ">
                <img src={sf85d} alt="Alumil Pergola" />
              </div>
              <div class="w-full ">
                <img src={sf85e} alt="Alumil Pergola" />
              </div>
              <div class="w-full ">
                <img src={sf85f} alt="Alumil Pergola" />
              </div>
              <div class="w-full ">
                <img src={PG120Shade} alt="Alumil Pergola" />
              </div>
              <div class="w-full ">
                <img src={PG160P} alt="Alumil Pergola" />
              </div>
            </div>
          </div>

        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            

          <h3 className="md:pl-32 font-Lato font-semibold text-left tracking-widest text-base underline decoration-4 decoration-[#607261] leading-6 underline-offset-8">
              GENERIC INFO
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-Lato  md:text-base text-left pt-6 uppercase ">

              <span className="pr-10 font-semibold ">
                  AESTHETICS
                  <span className="pr-10 block font-normal pb-4">minimal</span>
                </span>
                <span className="pr-10 font-semibold ">
                  USE
                  <span className="pr-10 block font-normal pb-4">adjustable shading, controlled living conditions</span>
                </span>
                <span className="pr-10 font-semibold ">
                  FUNCTIONALITY
                  <span className="pr-10 block font-normal pb-4">
                  bioclimatic, motorized louvers
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                  APPLICATIONS
                  <span className="pr-10 block font-normal pb-4">
                  adjustable shading in luxury residences, hotels, roof gardens, commercial spaces, restaurants, lounges and dining places
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                  ADDITIONAL FEATURES
                  <span className="pr-10 block font-normal pb-4">rolling shading fabric (ZIP), top-hung aluminium shutters, LED lighting, glass sliding system, heating and sound devices</span>
                </span>
                <span className="pr-10 font-semibold ">
                  PLACEMENT - INSTALLATION
                  <span className="pr-10 block font-normal pb-4">easy modular assembling</span>
                </span>
                <span className="pr-10 font-semibold ">
                  RAIN PROTECTION
                  <span className="pr-10 block font-normal pb-4">
                  special sealing and water drainage system, which guides the rain water from the roof to bottom of the posts.
                  </span>
                </span>
                
          </div>
          </div>

            <h3 className="md:pl-32 font-Lato font-semibold text-left tracking-widest text-base underline decoration-4 decoration-[#607261] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-Lato  md:text-base text-left pt-6 uppercase ">
               
                <span className="pr-10 font-semibold ">
                COLUMN DIMENSION
                  <span className="pr-10 block font-normal pb-4">160 x 160 mm</span>
                </span>
                <span className="pr-10 font-semibold ">
                LOUVERS
                  <span className="pr-10 block font-normal pb-4">190 mm with special gasket</span>
                </span>
                <span className="pr-10 font-semibold ">
                MAXIMUM DIMENSIONS
                  <span className="pr-10 block font-normal pb-4">
                  height 3.0 m, width 7.5 m and length 7.5 m
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                MECHANISM
                  <span className="pr-10 block font-normal pb-4">
                  concealed
                  </span>
                </span>
                

                <span className="pr-10 font-semibold ">
                LOUVERS ANGLE
                  <span className="pr-10 block font-normal pb-4">
                  0-135° degrees, all day shading
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                LIGHTING
                  <span className="pr-10 block font-normal pb-4">linear tape integrated at louvers and transoms</span>
                </span>
                <span className="pr-10 font-semibold ">
                SLIDING SYSTEM
                  <span className="pr-10 block font-normal pb-4">4-sash and 4+4-sash typologies</span>
                </span>
                <span className="pr-10 font-semibold ">
                SHADING SCREEN
                  <span className="pr-10 block font-normal pb-4">
                  2.8 x 6.0 m
                  </span>
                </span>

                <span className="pr-10 font-semibold ">
                SNOW WEIGHT
                  <span className="pr-10 block font-normal pb-4">
                  up to 300 Kg/m2 or 2.0 m of snow
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                AUTOMATION
                  <span className="pr-10 block font-normal pb-4">
                  remote control, weather sensors, smart apps
                  </span>
                </span>
                <span className="pr-10 font-semibold ">
                SURFACE TREATMENTS
                  <span className="pr-10 block font-normal pb-4">
                  seaside resistance without coating peeling off thanks to the possibility of pre-anodising, use of extruded aluminium fittings for enhanced protection against corrosion.
                  </span>
                </span>
               

              
              </div>
            </div>

            {/* <h3 className="md:pl-32 font-Lato font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#607261] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-6 md:pl-32 max-w-7xl text-sm md:text-base font-Lato text-left pt-6 ">
              <span className="pr-10 pb-4 ">
                <img
                  src={sf851}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24 mt-auto mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={sf852}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents plus door
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sf853}
                  alt="Alumil sf85 Typology "
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf854}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={sf855}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with single door
              </span>
              <span className="pr-10 pb-4">
                <img
                  src={sf856}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Corner construction with odd number of vents on both sides
              </span>

              <span className="pr-10 pb-4 ">
                <img
                  src={sf857}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24 mx-auto "
                />
                Odd number of vents on both sides (meeting stile)
              </span>
              <span className="pr-10 pb-4  ">
                <img
                  src={sf858}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents on one side
              </span>
              <span className="pr-10 pb-4  ">
                {" "}
                <img
                  src={sf859}
                  alt="Alumil sf85 Typology "
                  className="object-contain h-24  mx-auto"
                />
                Even number of vents plus door
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf8510}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Odd number of vents on one side
              </span>
              <span className="pr-10 pb-4">
                {" "}
                <img
                  src={sf8510}
                  alt="Alumil sf85 Typology"
                  className="object-contain h-24  mx-auto"
                />
                Even & odd number of vents on both sides (meeting stile)
              </span> */}
            {/* </div> */}

            {/* <h3 className="md:pl-32 md:pt-12 font-Lato font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3> */}
          </div>
         
        </div>
      </div>
      {/* <div className="  flex flex-col justify-center  pb-2">
        <div className="  md:max-w-9xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6  max-w-8xl;  mx-auto bg-black ">
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec1}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec2}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec3}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec4}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="  md:w-full md:2/2 grid place-items-center  ">
            <img
              src={sf85sec5}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:w-2/2 grid place-items-center  ">
            <img
              src={sf85sec6}
              alt="Alumil sf85 section"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div> */}

      <h3 className="md:pl-32 md:pt-12 font-Lato font-semibold text-left tracking-widest text-base underline decoration-4 decoration-[#607261] leading-6 underline-offset-8">
        RELATED FILES
      </h3>
      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-12 md:pb-12 items-center">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/general/alumil-pergola.pdf?sfvrsn=649368bf_38"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#607261" }}
            />{" "}
            Alumil Pergolas
          </a>
        </div>
      </div>

      {/* <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/supreme/supreme-sf85.pdf?sfvrsn=a54b85df_21"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#607261" }}
            />{" "}
            SF85 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/supreme/sf85_en.pdf?sfvrsn=4705adb6_35"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#607261" }}
            />{" "}
            SF85 Brochure
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Sf85;
